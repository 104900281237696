import styled from "styled-components"
import { themeGet } from "styled-system"

type LogoStyleProps = {}

const LogoStyle = styled("h1")<LogoStyleProps>`
  font-size: 25px;
  color: ${themeGet("colors.black", "#292929")};
  margin: 0;
  span {
    color: ${themeGet("colors.primary", "#D10068")};
  }
`

LogoStyle.displayName = "LogoStyle"

export default LogoStyle
