import React from "react"
import LogoStyle from "./Logo.style"

type LogoProps = {}

const Logo: React.FunctionComponent<LogoProps> = props => {
  return (
    <LogoStyle>
      Frederon<span>Blog</span>
    </LogoStyle>
  )
}

export default Logo
